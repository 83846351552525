import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import './style.scss'

class Post extends React.Component {
  render() {
    
    const {
      title,
      date,
      category,
      description,
      tags,
      //ore added
      featuredImage,
    } = this.props.data.node.frontmatter

    {/* 
    if (featuredImage == undefined) {
      
    }

    if (featuredImage == undefined) { console.log(this.props.data.node.frontmatter) 
      throw new Error( JSON.stringify(this.props.data.node.frontmatter )) }

    // const title = get(node, 'frontmatter.title') || node.fields.slug;
    
    */}

    // ore commented out following as well
    const { slug, categorySlug, tagSlugs} = this.props.data.node.fields
    // const image = this.props.data.imageOne.childImageSharp.fluid

    // ore added
    // const post = this.props.data.allMarkdownRemark.edges.node
    // const featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
    const featuredImgSize = featuredImage.childImageSharp.sizes.src
    // const image = `https://kinnikumegane.com/${featuredImgSize}`

    return (
      <div className="post">
        <div className="post__meta">
          <time
            className="post__meta-time"
            dateTime={moment(date).format('YYYY年M月DD日')}
          >
            {moment(date).format('YYYY年M月DD日')}
          </time>
          <span className="post__meta-divider" />
          <span className="post__meta-category" key={categorySlug}>
            <Link to={categorySlug} className="post__meta-category-link">
              {category}
            </Link>
          </span>
          <span className="post__meta-divider" />
          <span className="post__meta-divider" />
          <Link to={slug}>
            <div className="post__meta__title">
              <h1>{title}</h1>
            </div>
          </Link>

          {/* kinniku added tag */}
          
          
          {/*}
          <span className="post__meta-tag" key={tagSlugs}>
            <ul className="post__meta-tag_ul">
              {tagSlugs &&
                tagSlugs.map((tag, i) => (
                  <li className="post__meta-tag-list-item" key={tag}>
                    <Link to={tag} className="post__meta-tag-list-item-link">
                      {tags[i]}
                    </Link>
                    <span className="post__meta-tag-divider" />
                  </li>
                ))}
            </ul>
          </span>
                */}
          {/* kinniku added tag */}
        </div>

        <div className="post__oreadd">
          <Link to={slug}>
            <h2 className="post__oreadd__title">
              <div>
                <img
                  src={ featuredImgSize }
                  width="100%"
                  height="300px"
                  style = {{ objectFit: 'cover' }}
                />
              </div>
            </h2>
            <div className="post__oreadd__description">{description}</div>
          </Link>
        </div>
        <div className="post__readmore" >
          <Link to={slug}>
            続きを読む＞
          </Link>
        </div>
      </div>
    )
  }
}

export default Post